/* ------------------------------------------------------------------------
 * Verso: verso.parallax.js
 * Initialises parallax backgrounds
 * ------------------------------------------------------------------------
 * Copyright 2017 Oxygenna LTD
 * ------------------------------------------------------------------------ */

jQuery(document).ready(function($) {
    /********************
     Parallax Backgrounds
    /*******************/
    $('body:not(.--o--agent-touch) .section-background').each(function() {
    	$(this).parallaxBackground({
        	'image': '.section-bg-image-paralax'
    	});
    });
});
