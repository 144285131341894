jQuery(document).ready(function($){
  function closePanel(e) {
    e.preventDefault();
    faqsContainer.removeClass('slide-in').find('li').show();
    $('body').removeClass('cd-overlay');
  }

  function updateCategory(){
    updateCategoryPosition();
    updateSelectedCategory();
  }

  function updateCategoryPosition() {
    var top = $('.cd-faq').offset().top,
      height = jQuery('.cd-faq').height() - jQuery('.cd-faq-categories').height(),
      margin = 160;
    if( top - margin <= $(window).scrollTop() && top - margin + height > $(window).scrollTop() ) {
      var leftValue = faqsCategoriesContainer.offset().left,
        widthValue = faqsCategoriesContainer.width();
      faqsCategoriesContainer.addClass('is-fixed').css({
        'left': leftValue,
        'top': margin,
        '-moz-transform': 'translateZ(0)',
          '-webkit-transform': 'translateZ(0)',
        '-ms-transform': 'translateZ(0)',
        '-o-transform': 'translateZ(0)',
        'transform': 'translateZ(0)',
      });
    } else if (top - margin + height <= $(window).scrollTop()) {
      var delta = top - margin + height - $(window).scrollTop();
      faqsCategoriesContainer.css({
        '-moz-transform': 'translateZ(0) translateY('+delta+'px)',
          '-webkit-transform': 'translateZ(0) translateY('+delta+'px)',
        '-ms-transform': 'translateZ(0) translateY('+delta+'px)',
        '-o-transform': 'translateZ(0) translateY('+delta+'px)',
        'transform': 'translateZ(0) translateY('+delta+'px)',
      });
    } else {
      faqsCategoriesContainer.removeClass('is-fixed').css({
        'left': '100px',
        'top': '100px',
      });
    }
  }

  function updateSelectedCategory() {
    faqsSections.each(function(){
      var actual = $(this),
        margin = parseInt($('.cd-faq-title').eq(1).css('marginTop').replace('px', '')),
        activeCategory = $('.cd-faq-categories a[href="#'+actual.attr('id')+'"]'),
        topSection = (activeCategory.parent('li').is(':first-child')) ? 0 : Math.round(actual.offset().top);

      if (( topSection - 20 <= $(window).scrollTop() ) && ( Math.round(actual.offset().top) + actual.height() + margin - 20 > $(window).scrollTop() )) {
        activeCategory.addClass('selected');
      } else {
        activeCategory.removeClass('selected');
      }
    });
  }

  //update these values if you change these breakpoints in the style.css file (or _layout.scss if you use SASS)
  var MqM = 768,
    MqL = 1024;

  if ($('.cd-faq').length == 0) { return; }

  var faqsSections = $('.cd-faq-group'),
    faqTrigger = $('.cd-faq-trigger'),
    faqsContainer = $('.cd-faq-items'),
    faqsCategoriesContainer = $('.cd-faq-categories'),
    faqsCategories = faqsCategoriesContainer.find('a');

  //select a faq section
  faqsCategories.on('click', function(event) {
    event.preventDefault();
    var selectedHref = $(this).attr('href');
    var target = $(selectedHref);
    faqsContainer.scrollTop(0).addClass('slide-in').children('ul').removeClass('selected').end().children(selectedHref).addClass('selected');
    $('body').addClass('cd-overlay');
  });

  //show faq content clicking on faqTrigger
  faqTrigger.on('click', function(event){
    event.preventDefault();
    $(this).parent('h3').next('.cd-faq-content').slideToggle(200).end().parent('li').toggleClass('content-visible');
  });

  //update category sidebar while scrolling
  $(window).on('scroll', function(){
    if ( $(window).width() > MqL ) {
      (!window.requestAnimationFrame) ? updateCategory() : window.requestAnimationFrame(updateCategory);
    }
  });

  $(window).on('resize', function(){
    if($(window).width() <= MqL) {
      faqsCategoriesContainer.removeClass('is-fixed').css({
        '-moz-transform': 'translateY(0)',
          '-webkit-transform': 'translateY(0)',
        '-ms-transform': 'translateY(0)',
        '-o-transform': 'translateY(0)',
        'transform': 'translateY(0)',
      });
    }
    if( faqsCategoriesContainer.hasClass('is-fixed') ) {
      faqsCategoriesContainer.css({
        'left': faqsContainer.offset().left,
      });
    }
  });
});
